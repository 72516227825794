/**
 * Timetastic
 * Holiday calculator page
 */
import React, { useEffect } from "react"
// import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

// Helpers
import { animate, smoothScroll, trackCta } from "src/helpers"

// Components
import BackgroundBlobs from "../components/images/BackgroundBlobs"
import Footer from "src/components/footer"
import FooterCta from "src/components/footerCta"
import FreeTrial from "src/components/freeTrial"
import Header from "src/components/header"
import HolidayCalculator from "src/components/holidayCalculator"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import SignupButton from "src/components/signupButton"

// Styles
import "src/styles/templates/downloads.scss"

// SEO
const title = "Holiday entitlement calculator"
const description =
  "A holiday allowance calculator provided by Timetastic - the online staff leave planner for modern companies."

// JSX
const Default = () => {
  // Animate any elements with a class of 'is-unanimated'
  useEffect(() => {
    animate()
  }, [])

  return (
    <>
      <SEO title={title} description={description} />
      <Layout>
        <Header />
        <FooterCta />
        <main>
          <span className="c-holiday-calculator-anchor" id="calculator" />
          <section className="c-section c-section-holiday is-unanimated">
            <div className="c-holiday-calculator__panel">
              <BackgroundBlobs blobType="holidayCalcBlobs">
                <div className="u-inner u-inner--ms c-holiday-calculator-container">
                  <h1 className="h h2 c-download-hero__title u-text-centre u-text-left-mobile">
                    <span className="u-bgcolor-gradient u-bgcolor-gradient-text">
                      Holiday entitlement
                    </span>{" "}
                    calculator
                  </h1>
                  <p className="u-text-centre u-text-left-mobile">
                    Use our holiday entitlement calculator to determine how much
                    annual leave allowance an employee has left.
                    <br />
                    You can calculate for a full year or a pro-rata value for
                    any part of the year.
                  </p>

                  <HolidayCalculator />
                </div>
              </BackgroundBlobs>
            </div>
            <div className="c-timetastic-callout u-inner u-inner--ms u-text-centre">
              <h2 className="h h4">
                Discover how thousands of SMEs use Timetastic to calculate
                annual leave
              </h2>
              <p className="callout-copy">
                Say goodbye to manual spreadsheets and hello to Timetastic’s
                seamless{" "}
                <Link
                  className="u-em-link u-hover-link"
                  to="/staff-leave-planner/"
                  onClick={() => {
                    trackCta("HolidayCalculatorCTA")
                  }}
                >
                  <span>employee holiday booking system</span>
                </Link>
                . Calculate annual leave easily, giving back you and your
                business time, transparency and control.
              </p>
              <div className="c-video__player">
                <iframe
                  title="See Timetastic in action"
                  src="https://player.vimeo.com/video/880192428?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  allowFullScreen={true}
                  loading="lazy"
                ></iframe>
              </div>
              <SignupButton
                buttonCopy="Get a free trial"
                trackCtaLabel="HolidayCalculatorVideoSignup"
              />
              <p className="c-free-trial__no-link">
                Free for one month - no card required
              </p>
            </div>
          </section>

          <section className="c-section c-section-holiday-questions">
            <div className="u-inner u-inner--l">
              <h2 className="h h2 u-text-centre u-text-left-mobile is-unanimated">
                Holiday allowance calculator FAQs
              </h2>
              <div className="c-download-faq">
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    What is the statutory entitlement of annual leave in the UK?
                  </h3>
                  <p>
                    In the UK all full-time workers are entitled to a minimum of
                    5.6 weeks (28 days) paid holiday per year. This is known as
                    "Statutory leave entitlement" and this can also include bank
                    holidays.
                  </p>
                  <p>
                    Employers must not round down holiday entitlement, it can
                    only be rounded up.
                  </p>
                  <p>
                    Public holidays can be included as part of that 28 days.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    What is pro-rata annual leave entitlement and how can I work
                    this out?
                  </h3>
                  <p>
                    Pro-rata holiday entitlement is calculated based on how much
                    annual leave an employee is entitled to depending on the
                    time they have worked across a respective holiday year.
                    Part-time workers are afforded the same annual leave
                    entitlement as full-time workers, however as fewer hours are
                    worked, this will mean your part-time employees will have
                    less than 28 days statutory leave accrued due to reduced
                    working hours.
                  </p>
                  <p>
                    If you have any employee on a zero-hours contract, you can
                    use our handy holiday allowance calculator above to work out
                    the number of hours they have accrued to work out any annual
                    leave allowance. Using{" "}
                    <Link
                      className="u-em-link u-hover-link"
                      to="/absence-management-software/"
                      onClick={() => {
                        trackCta("HolidayCalculatorFaqProRata")
                      }}
                    >
                      <span>absence management software</span>
                    </Link>{" "}
                    like Timetastic will give you greater transparency over
                    annual leave entitlements.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    What happens if an employee leaves partway through the
                    holiday year?
                  </h3>
                  <p>
                    If an employee leaves part way through the holiday year,
                    they are entitled to part of their statutory entitlement
                    based on how much of the leave year they are employed by
                    you. If an employee has taken more than their annual leave
                    entitlement when they are set to leave the business then you
                    can communicate your intention to deduct the amount from the
                    final pay in writing to your employee. On the flip side, you
                    can offer{" "}
                    <a
                      className="u-em-link u-hover-link"
                      href="https://timetastic.co.uk/blog/pilon-payment-in-lieu-of-notice-how-it-works"
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackCta("HolidayCalculatorFaqPilon")
                      }}
                    >
                      <span>payment in lieu</span>
                    </a>{" "}
                    to cover the total number of holidays they have left.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    How do I calculate annual leave for bank holidays and any
                    additional holiday days?
                  </h3>
                  <p>
                    In recent years, the passing of Queen Elizabeth II and the
                    Coronation of King Charles III has meant there have been
                    additional bank holidays added to the calendar. This causes
                    panic and headaches for HR managers and business owners
                    alike, but we can alleviate any stress here.
                  </p>
                  <p>
                    Workers don’t have a statutory right to not work bank
                    holidays. It may come as a surprise, but you get the final
                    say on whether bank holidays are included in holiday
                    entitlement allowances. Whatever you choose, this should be
                    outlined in your staff handbook or contracts to give
                    employees peace of mind and know what they are obliged to
                    take and when.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    How is annual leave calculated for part-time workers?
                  </h3>
                  <p>
                    Any employee working less than 5 days per week is regarded
                    as part-time. Although they are entitled to 5.6 weeks of
                    annual leave, this will amount to fewer than 28 days.
                  </p>
                  <p>
                    The 28 days are pro-rated according to how many days per
                    week they work. For example, an employee who works 3 days
                    per week will earn 3/5ths of 28 days. 16.8 days. Our{" "}
                    <Link
                      className="u-em-link u-hover-link"
                      to="/staff-leave-planner/"
                      onClick={() => {
                        trackCta("HolidayCalculatorFaqPartTime")
                      }}
                    >
                      <span>staff leave planner</span>
                    </Link>{" "}
                    can give you great visibility with specialist icons for
                    those who are full-time or part-time, so you know exactly
                    where you stand.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">Can I offer more statutory leave?</h3>
                  <p>
                    Of course, as an employer, you are welcome to offer more
                    statutory leave and this is very common.
                  </p>
                  <p>
                    The rules that apply to statutory leave do not apply to the
                    additional. For example an employee may need to be employed
                    for a certain duration before being entitled to the
                    additional allowance.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    What is the upper limit of statutory leave?
                  </h3>
                  <p>
                    The upper limit of statutory leave is also 5.6 weeks. So
                    although it can be pro-rated downwards for employees working
                    less than 5 days per week, it does not increase for those
                    working 6 or 7 days. For those workers, statutory leave will
                    stay at 5.6 weeks.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    Can I tell my employees when to take annual leave?
                  </h3>
                  <p>
                    The nature of your business and seasonality may determine
                    that you need cover during bank holidays or the Christmas
                    period. If so, you are entitled to specify that workers must
                    take annual leave from their entitlement if they wish to
                    have this time off. This will be deducted from annual leave
                    allowances as normal.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    What is ‘Carry Over’ and does it impact how to calculate
                    annual leave?
                  </h3>
                  <p>
                    Carry forward of annual leave is normally a contractual
                    provision agreed between you and your employee.
                  </p>
                  <p>
                    The only statutory obligation to allow an employee to carry
                    leave forward into the next year is when sickness or
                    maternity prevents the employee from taking their
                    entitlement. In this case a maximum of 20 days can be
                    carried forward.
                  </p>
                </div>
                <div className="c-pricing-question is-unanimated">
                  <h3 className="h h4">
                    Where else can I find out more information on annual leave?
                  </h3>
                  <p>
                    The excellent gov.uk website provides more detailed guidance
                    on{" "}
                    <a
                      className="u-em-link u-hover-link"
                      href="https://www.gov.uk/holiday-entitlement-rights"
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackCta("HolidayCalculatorGovUK")
                      }}
                    >
                      <span>Holiday pay and emploment rights</span>
                    </a>
                    .
                  </p>
                  <p>
                    As too does{" "}
                    <a
                      className="u-em-link u-hover-link"
                      href="https://www.acas.org.uk/checking-holiday-entitlement"
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackCta("HolidayCalculatorACAS")
                      }}
                    >
                      <span>
                        ACAS (Advisory, Conciliation and Arbitration Service)
                      </span>
                    </a>
                  </p>
                  <p>
                    We also have some superb free resources with lots more
                    information on how you can use Timetastic, such as our{" "}
                    <a
                      className="u-em-link u-hover-link"
                      href="https://timetastic.co.uk/blog/time-off-policies/"
                      rel="noopener noreferrer"
                      target="_blank"
                      onClick={() => {
                        trackCta("HolidayCalculatorTOPolicies")
                      }}
                    >
                      <span>time off policies</span>
                    </a>{" "}
                    blog section.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <FreeTrial
            body="Over 7,000 companies have upgraded from old style paper forms and spreadsheets. They now manage their team’s absence and holiday allowances with Timetastic."
            buttonCopy="Start your free trial"
            buttonHref="/pricing/"
            title="Try Timetastic for free today"
            trackCtaLabel="HolidayCalculatorSignUp"
          />
        </main>
        <Footer />
      </Layout>
    </>
  )
}
export default Default

export const query = graphql`
  query {
    placeholderImage: file(
      relativePath: { eq: "../images/hero-blobs-landscape.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 100, formats: [AUTO, WEBP])
      }
    }
  }
`
