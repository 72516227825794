/**
 * Timetastic
 * Holiday Calculator component
 */
import React, { useRef, useState } from "react"
import dayjs from "dayjs"
import axios from "axios"
import isLeapYear from "dayjs/plugin/isLeapYear"
import { Link } from "gatsby"
import  DatePicker from "react-datepicker"

// Helpers
import { trackCta, trackFormError } from "src/helpers"

// Styles
import "./holidayCalculator.scss"
import "react-datepicker/dist/react-datepicker.css";


// IsLeapYear adds .isLeapYear API to returns a boolean indicating whether the dayjs's year is a leap year or not.
dayjs.extend(isLeapYear)

//Classes/Constants to replace Magic Strings everywhere...
/// Matches the Enum on the server side
var CalculationTypes = {
  "FullYear": 1,
  "StartPartway": 2,
  "EndPartway": 3,
  "StartEndPartway": 4
}

const formatDate = date => {
  console.log("formatting a date: ", date);
  const year = date.getFullYear()
  const day = `0${date.getDate()}`.slice(-2)
  const month = `0${date.getMonth() + 1}`.slice(-2)

  return `${year}-${month}-${day}`
}

// const calculateLeaveEndYear = value => {
//   let newValue = dayjs(value).add(1, "year").subtract(1, "d")
// console.log("calculateLeaveEndYear --> Got " + value);
// console.log("calculateLeaveEndYear --> returning " + newValue.toDate());
//   return newValue.toDate()
// }

// JSX
const HolidayCalculator = () => {
  const [allowance, setAllowance] = useState(null)
  const [allowanceHours, setAllowanceHours] = useState(null)
  const [allowanceMinutes, setAllowanceMinutes] = useState(null)
  const [allowReset, setAllowReset] = useState(false)
  const [daysPerWeek, setDaysPerWeek] = useState(5)
  const [employmentStart, setEmploymentStart] = useState(new Date())
  const [employmentEnd, setEmploymentEnd] = useState(dayjs(new Date()).add(1, "year").subtract(1, "d").toDate())
  const [hoursPerWeek, setHoursPerWeek] = useState(0)
  const [leaveYearStart, setLeaveYearStart] = useState(new Date())
  const [period, setPeriod] = useState(CalculationTypes.FullYear)
  const [unit, setUnit] = useState("days")
  const [errorMessage, setError] = useState("");

  const refForm = useRef()
  const refAllowance = useRef()

  


  const reset = () => {
    setAllowance(null)
    setAllowanceHours(null)
    setAllowanceMinutes(null)
    setDaysPerWeek(5)
    setEmploymentStart(new Date())
    setEmploymentEnd(dayjs(new Date()).add(1, "year").subtract(1, "d").toDate())
    setHoursPerWeek(0)
    setLeaveYearStart(new Date())
    setPeriod(CalculationTypes.FullYear)
    setUnit("days")
    setError("")
  }

  /**
   * Send the data off to our public API for the calculation
   *
   * @param {object} event - form submission event
   */
  const calculate = event => {
    event.preventDefault()

    var payload = {
      IsDailyCalculation: unit === "days",
      DaysPerWeek: daysPerWeek,
      HoursPerWeek: hoursPerWeek,
      AllowanceCalculationType: period,
      LeaveYearStart: leaveYearStart,
      EmployeeStart: employmentStart,
      EmployeeEnd: employmentEnd
    };

    // console.dir(payload);

    axios({
      method: "post",
      url: "https://app.timetastic.co.uk/api/AllowanceCalculator",
      data: payload,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        if (response.status >= 200 && response.status <= 299) {
          // console.dir(response);
          if (unit === "days") {
            setAllowance(response.data.allowanceString)
            setAllowanceHours(null)
            setAllowanceMinutes(null)
          }
          else {
            //The react shows any value if it's not null, so set 0 to null here.
            if (response.data.allowanceParts[0] === 0)
              response.data.allowanceParts[0] = null

            if (response.data.allowanceParts[1] === 0)
              response.data.allowanceParts[1] = null

            setAllowanceHours(response.data.allowanceParts[0])
            setAllowanceMinutes(response.data.allowanceParts[1])
          }
        } else {
          //Non 200 response:
          setError("Connection error, please try again");
        }
      })
      .catch(error => {
        const errorMessage =
          error.response && error.response.data
            ? error.response.data
            : error.toString()

        setError("Connection error, please try again");
        trackFormError(`AllowanceCalculator - ${errorMessage}`)
      })

    refAllowance.current.focus()
  }

  return (
    <div className="c-holiday-calculator">
      <div>
        <form
          className=""
          ref={refForm}
          onSubmit={calculate}
        >
          <div className="c-holiday-calculator__form-col u-bgcolor-light-blue">
            <fieldset className="c-holiday-calculator__section">
              <legend className="c-form-label c-form-label--hc">
                Is your allowance based on?
              </legend>
              {/* unit selector */}
              <div className="radio">
                <input
                  checked={unit === "days"}
                  id="unit_days"
                  name="unit"
                  required={true}
                  type="radio"
                  value="days"
                  onChange={e => {
                    setUnit(e.target.value)
                    setHoursPerWeek(0)
                  }}
                />
                <label htmlFor="unit_days" className="radio-label">
                  Days
                </label>
              </div>
              <div className="radio">
                <input
                  checked={unit === "hours"}
                  id="unit_hours"
                  name="unit"
                  required={true}
                  type="radio"
                  value="hours"
                  onChange={e => {
                    setUnit(e.target.value)
                  }}
                />
                <label htmlFor="unit_hours" className="radio-label">
                  Hours
                </label>
              </div>
            </fieldset>
          </div>
          <div className="c-holiday-calculator__form-col u-bgcolor-light-blue">
            <fieldset className="c-holiday-calculator__section">
              {/* Calculation Type */}
              <legend className="c-form-label c-form-label--hc">
                What period does it cover?
              </legend>
              <div className="radio">
                <input
                  checked={period === CalculationTypes.FullYear}
                  id="full_year"
                  name="type"
                  required={true}
                  type="radio"
                  value={CalculationTypes.FullYear}
                  onChange={() => {
                    setPeriod(CalculationTypes.FullYear)
                  }}
                />
                <label htmlFor="full_year" className="radio-label">
                  Full year
                </label>
              </div>
              <div className="radio">
                <input
                  checked={period === CalculationTypes.StartPartway}
                  id="start_partway"
                  name="type"
                  required={true}
                  type="radio"
                  value={CalculationTypes.StartPartway}
                  onChange={e => {
                    setPeriod(CalculationTypes.StartPartway)
                  }}
                />
                <label htmlFor="start_partway" className="radio-label">
                  Starting part way through the year
                </label>
              </div>
              <div className="radio">
                <input
                  checked={period === CalculationTypes.EndPartway}
                  id="leave_partway"
                  name="type"
                  required={true}
                  type="radio"
                  value={CalculationTypes.EndPartway}
                  onChange={e => {
                    setPeriod(CalculationTypes.EndPartway)
                  }}
                />
                <label htmlFor="leave_partway" className="radio-label">
                  Leaving part way through the year
                </label>
              </div>
              <div className="radio">
                <input
                  checked={period === CalculationTypes.StartEndPartway}
                  id="partial_year"
                  name="type"
                  required={true}
                  type="radio"
                  value={CalculationTypes.StartEndPartway}
                  onChange={e => {
                    setPeriod(CalculationTypes.StartEndPartway)
                  }}
                />
                <label htmlFor="partial_year" className="radio-label">
                  Only part of the year
                </label>
              </div>
            </fieldset>
            {unit === "hours" && (
              <div id="hours-container" className="c-holiday-calculator__section">
                <div>
                  {/* Hours per week */}
                  <label htmlFor="hours_per_week">
                    How many hours per week do you work?
                  </label>
                  <span className="c-form-hint">
                    Enter a number, from 1 to 168
                  </span>
                  <input
                    id="hours_per_week"
                    max="168"
                    min="1"
                    required={true}
                    type="number"
                    value={hoursPerWeek}
                    onChange={e => {
                      setHoursPerWeek(e.target.value)
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="c-holiday-calculator__form-col u-bgcolor-light-blue">
            <div id="days-container" className="c-holiday-calculator__section">
              <div>
                {/* Days worked */}
                <label
                  className="c-form-label c-form-label--hc"
                  htmlFor="days_per_week"
                >
                  How many days per week do you work?
                </label>
                <span className="c-form-hint">Enter a number, from 1 to 7</span>
                <input
                  id="days_per_week"
                  max="7"
                  min="1"
                  required={true}
                  type="number"
                  value={daysPerWeek}
                  onChange={e => {
                    setDaysPerWeek(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>

          {(period === CalculationTypes.StartPartway || period === CalculationTypes.EndPartway) && (
            <div id="leaveyear-start" className="c-holiday-calculator__section">
              <div className="c-holiday-calculator__form-col u-bgcolor-light-blue">
                <label
                  className="c-form-label c-form-label--hc"
                  htmlFor="leave_year"
                >
                  When does your leave year start?
                </label>
                <DatePicker
                  className="c-datepicker"
                  dateFormat="dd-MMM-yyyy"
                  selected={leaveYearStart}
                  showMonthYearDropdown
                  dateFormatCalendar={"MMM yyyy"}
                  minDate={dayjs().subtract(1, 'y').toDate()}
                  maxDate={dayjs().add(1, 'y').toDate()}
                  onChange={date => {
                    console.log("leaveyearstart --> datepickerchange", date);

                    setLeaveYearStart(date)
                  }}
                />
             
                
              </div>
            </div>
          )}

          {(period === CalculationTypes.StartPartway || period === CalculationTypes.StartEndPartway) && (
            <div className="c-holiday-calculator__section">
              <div className="c-holiday-calculator__form-col u-bgcolor-light-blue">
                <label className="c-form-label c-form-label--hc" htmlFor="start">
                  When does your employment start?
                </label>
                <DatePicker
                  className="c-datepicker"
                  selected={employmentStart}
                  dateFormat="dd-MMM-yyyy"
                  showMonthYearDropdown
                  dateFormatCalendar={"MMM yyyy"}
                  minDate={dayjs().subtract(1, 'y').toDate()}
                  maxDate={dayjs().add(1, 'y').toDate()}
                  onChange={date => {
                    console.log("employmentStart --> datepickerchange", date);

                    setEmploymentStart(date)
                  }}
              />
              
               
              </div>
            </div>
          )}

          {(period === CalculationTypes.EndPartway || period === CalculationTypes.StartEndPartway) && (
            <div className="c-holiday-calculator__section">
              <div className="c-holiday-calculator__form-col u-bgcolor-light-blue">
                <label className="c-form-label c-form-label--hc" htmlFor="end">
                  What date does your employment end?
                </label>
                <DatePicker
                  className="c-datepicker"
                  selected={employmentEnd}
                  dateFormat="dd-MMM-yyyy"
                  showMonthYearDropdown
                  dateFormatCalendar={"MMM yyyy"}
                  minDate={dayjs().subtract(1, 'y').toDate()}
                  maxDate={dayjs().add(1, 'y').toDate()}
                  onChange={date => {
                    console.log("employmentEnd --> datepickerchange", date);
                     setEmploymentEnd(date)
                  }}
                />
             
                
              </div>
            </div>
          )}

          <button
            className="c-button c-button--primary c-button--xl c-holiday-calculator__submit"
            onClick={() => {
              setAllowReset(true)
              trackCta("HolidayCalculatorCalculate")
            }}
            type="submit"
          >
            Calculate
          </button>
        </form>
      </div>
      <div className="c-holiday-calculator__side">
        <div className="c-holiday-calculator__content">
          <div
            className="c-allowance u-text-centre"
            ref={refAllowance}
            tabIndex="-1"
          >
            <h2 className="h h4">Your allowance</h2>
            <div className="allowance__value h h2">
              {allowanceHours || allowance || "-"}
            </div>
            <span className="allowance__unit h h5">{unit}</span>

            {allowanceMinutes && (
              <>
                <div className="allowance__value h h2">{allowanceMinutes}</div>
                <span className="allowance__unit h h5">minutes</span>
              </>
            )}
          </div>
          {allowReset && (
            <div className="c-reset u-text-center">
              <Link to="#calculator">
                <button
                  className="c-button c-button--xl c-holiday-calculator__reset"
                  type="reset"
                  onClick={() => {
                    reset()
                    trackCta("HolidayCalculatorReset")
                  }}
                >
                  Start again
                </button>
              </Link>
              <p className="c-error">
                {errorMessage}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HolidayCalculator
